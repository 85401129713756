import React from 'react'
import 'bootstrap'
import { Container } from 'react-bootstrap';

import './Ingrasaminte.css'

function Ingrasaminte() {


  document.documentElement.style.setProperty('--color', `white`);
  document.body.style.setProperty('--background-image',"url('images/ingrasaminte_pelicula.jpg')");
  return (
    <div className='container-fluid'>
      <Container>
        <br/>
        <br/>
      <p class="fs-1">CE OFERIM?</p>
      
      </Container>
            
      <p className = "text-center"> -Tehnologii avansate în sectorul agriculturii ecologice <br/>
      -Furnizarea unui portofoliu vast de produse Ecologice <br/>
      -Consultanță, Certificare si Seminarii de pregătire în agricultura ecologică <br/>
      </p>


      <br/>
      <p className = "normal-text">Prin seriozitatea, devotamentul și munca în domeniul Agriculturii Ecologice din toți acești an, am reușit să adunăm un grup de Ingineri, Fermieri, Cercetători, Procesatori, Producători de inputuri .
Cu un sistem bine stabilit, portofoliul vast de produse și diferitele parteneriate cu procesatori, asigurăm fermierii de toată tehnologia necesară unei culturi ecologice dar și de siguranța valorificării producției. </p>
    <br/>
    <p className = "normal-text">Cumpărăm cereale la preţuri competitive cu plata pe loc , asigurând transportul acestora.
</p>
    
    <br/>
    <p className = "normal-text">Soluțiile personalizate oferite, corespund nevoilor și cerințelor fermierilor, și reprezintă motorul acțiunilor noastre, iar fermierul este cea mai importantă verigă a lanțului către un viitor mai sănătos.</p>
    </div>
  )

}

export default Ingrasaminte
