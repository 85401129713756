import React from 'react'
import 'bootstrap'
import { Container } from 'react-bootstrap';
import './Ingrasaminte.css'


export default function MotorinaDomiciliu() {

  

  document.documentElement.style.setProperty('--color', `white`);
  document.body.style.setProperty('--background-image',"url('images/station_pelicula.jpg')");
  return (
    <div className='container-fluid'>

        <br/>
        <br/>       
        <br/>
      <p className = "normal-text">Timpul și activitatea intensă nu vă permite să vă deplasați de fiecare dată în stații pentru a alimenta? Aveți activitate cu utilaje care trebuie alimentate ?  </p>
      <br/>
      <p className = "text-center">Nicio problemă, aici intervenim noi!
 </p>
    <br/>
    <p className = "normal-text">Prin serviciul de livrare motorină la domiciliu , suntem alături de dumneavoastră și vă oferim carburanții necesari, în cantitatea solicitată. Livrarea se va face la locul consumului, utilizând flota proprie de cisterne.
  </p>
    <p className='normal-text'>Ținem cont de nevoile specifice ale clienților prin serviciul de livrare motorină vrac direct la locul consumului.
Cu serviciul nostru rapid, eficient și fiabil de livrare vrac, vehiculele sau utilajele nu se vor bloca in curte, atunci când acestea ar trebui să fie pe câmpul de lucru. Putem aranja livrări de combustibil lunar, săptămânal sau chiar zilnic.</p>
    <br/>
    <br/>
    <p className='text-center'>Așa că, dacă aveți nevoie de motorină vrac cu livrare la locul unde desfășurați activitatea, ați ajuns în locul potrivit!</p>
    </div>
  )

}

