import React from 'react'
import 'bootstrap'
import { Container } from 'react-bootstrap';

import './Ingrasaminte.css'

function Cereale() {
  document.documentElement.style.setProperty('--color', `white`);
  document.body.style.setProperty('--background-image',"url('images/wheat_pelicula.jpg')");
  return (
    <div className='container-fluid'>
      <Container>
        <br/>
        <br/>
     
        <br/>      
      </Container>
            
      <p className = "normal-text">Interglobal MB OIL SRL achiziţionează toate tipurile de cereale provenite din culturi agricole: grâu, orz, rapiţă, floarea- soarelui, porumb, triticale, soia, mazăre, şi altele. </p>
      <br/>
      <p className = "normal-text">Dezvoltăm parteneriate profitabile şi de lungă durată cu fermierii, începând de la finanţare până la achiziţionarea recoltei, acordându-le, la cerere, inputuri . </p>
    <br/>
    <p className = "normal-text">Cumpărăm cereale la preţuri competitive cu plata pe loc , asigurând, transportul acestora.
</p>
    <br/>
    <p className = "text-center">Departamentul de Achiziţii vă stă  la dispoziţie cu cele mai bune preţuri de achiziţionare ale cerealelor!</p>
    </div>
  )

}

export default Cereale
