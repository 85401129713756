import React from 'react';
import  '../../App.css';
import Carousel from 'react-bootstrap/Carousel'
import './Ingrasaminte.css'

import 'bootstrap'
import { Container } from 'react-bootstrap';


export default function Carduri() {
  document.documentElement.style.setProperty('--color', `white`);
  document.body.style.setProperty('--background-image',"url('images/fuel_pelicula.jpg')");
  return (
    <div className='container-fluid'>
      <Container>
        <br/>
        <br/>
      <p class="fs-1">CARDURI PREPAID CARBURANT</p>
      
      </Container>
        <br/>    
      <p className = "normal-text">Acesta este modul în care pot fi sprijinite companiile care au deplasări interne, care doresc să controleze ușor și eficient costurile legate de deplasări, utilizând combustibili de înaltă calitate. Vă oferim posibilitatea de plată în avans, pentru a asigura un control strict și achiziții de combustibil programate.</p>
      <br/>
      <p className='text-center'>Cu cardul Fill&Go alimentezi şi plăteşti direct la pompă. </p>
      <p className = "normal-text">
      <img src="images/carduri/card1.png" class="rounded mx-auto d-block" alt="..." width={500} height={300}/>
Este un card de companie securizat cu cod PIN care îți permite achiziționarea pe debit a carburanților și produselor din stațiile Rompetrol din România și din Bulgaria*.</p>
      <br/>
      <p className='text-center'>Cardul MOL Gold Prepaid
Siguranță, eficiență a costurilor, calitate pentru companii. </p> 
<img src="images/carduri/card2.png" class="rounded mx-auto d-block" alt="..." width={500} height={300}/>
    <p className = "normal-text">Plata în avans
Cardul MOL Gold Prepaid vă permite să vă alimentați contul de card cu o sumă de bani definită, înainte de alimentare. În plus, clienții care folosesc Gold Prepaid beneficiază și de o reducere față de prețul de la pompă.
Alimentați mai ieftin, fără taxe inutile de tranzacție, fără să plătiţi în numerar.
</p>
    <br/>
    <p className = "normal-text" >Sunt disponibile atât transferul bancar, cât și reîncărcarea la stațiile de alimentare. În timp ce timpul total pentru transferul bancar este de 2 zile lucrătoare, reîncărcarea la stație este online, deci banii apar pe loc în contul dumneavoastră de card. De asemenea, nu există nicio limită a numărului de reîncărcări pe lună, puteți alimenta bani în contul dumneavoastră de card ori de câte ori doriți.</p>
 
    </div>
  )
}
