import React, { Component } from 'react'
import { Navbar, Container, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './NavbarB.css';




export default class NavbarB extends Component {
  render() {
    return (
      <Router>
        <div>

          <Navbar  bg="dark" variant = "dark" expand="lg" fixed="top" >
            <Container>
              <Navbar.Brand href="/">InterGlobal</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="/carduri">Carduri</Nav.Link>
                  <NavDropdown  menuVariant="dark" title="Motorină" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/enGross">Motorină enGross</NavDropdown.Item>
                    <NavDropdown.Item href="/domiciliu">Motorină domiciliu</NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown menuVariant="dark" title="Agricultură" id="basic-nav-dropdown">
                    <NavDropdown.Item href="/cereale">Cereale</NavDropdown.Item>
                    <NavDropdown.Item href="/ingrasaminte">Îngrășăminte bio</NavDropdown.Item>
                  </NavDropdown>
              
                  <Nav.Link href="/recenzii">Recenzii</Nav.Link>

                  <Nav.Link href="/contact">Contact</Nav.Link>

                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>

      </Router>
    )
  }
}